import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StepOverviewComponent } from '../step-overview/step-overview.component';
import { CategoryService } from 'src/app/services/api/category.service';
import { ICategory } from 'src/app/models/icategory';

@Component({
  selector: 'app-step-categories',
  templateUrl: './step-categories.component.html',
  styleUrls: ['./step-categories.component.scss']
})
export class StepCategoriesComponent implements OnInit {
  public categories: ICategory[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService
  ) { }

  ngOnInit() {
    StepOverviewComponent.resetScrollPosition();

    this.route.params.subscribe(params => {
      const id = +params.id;
      this.categoryService.getChildCategories(id).subscribe((categories: ICategory[]) => this.categories = categories);
    });
  }

  btnSelectCategory(category: ICategory, event?) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.router.navigate(['overview', category.id]);
  }

  btnGoBack(event?) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.router.navigateByUrl('types');
  }
}
