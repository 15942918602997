import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from './services/locale.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  static UrlParamLanguage = 'language';
  static UrlParamShop = 'shop';
  static UrlParamLocation = 'location';

  title = 'busters-gloves-webapp';

  constructor(
    private route: ActivatedRoute,
    private localeService: LocaleService
  ) {
    this.localeService.setDefault();
  }

  ngOnInit() {
    this.localeService.restoreSaved().subscribe(() => {
      this.route.queryParams.subscribe(params => {
        if(Object.keys(params).length) {
          this.handleParams(params);
        }
      });
    });
  }

  handleParams(params) {
    let shop = params[AppComponent.UrlParamShop];
    let location = params[AppComponent.UrlParamLocation];
    let lang = params[AppComponent.UrlParamLanguage];

    if(shop) {
      console.log('shop param is:', shop);
    }

    if(location) {
      console.log('location param is:', location);
    }

    if(lang) {
      console.info('should set language to:', lang);
      this.localeService.use(lang).subscribe(() => {
        console.info('language set to:', lang);
      });
    }
  }
}
