import { Component, OnInit, Input } from '@angular/core';
import { IEnCode } from 'src/app/models/ien-code';

@Component({
  selector: 'app-en3882-cut-resistance',
  templateUrl: './en3882-cut-resistance.component.html',
  styleUrls: ['./en3882-cut-resistance.component.scss']
})
export class En3882CutResistanceComponent implements OnInit {

  public options: number[] = [0, 1, 2, 3, 4, 5];

  @Input() public enCode: IEnCode;

  constructor() { }

  ngOnInit() {
  }

}
