import { Injectable, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  static StorageKeyLocale = 'busters-locale';

  public defaultLocale = 'NL';
  public currentLocale;

  @Output() onLanguageChanged: EventEmitter<string> = new EventEmitter();

  constructor(
    public translateService: TranslateService
  ) {
    
  }

  getSupportedLocals(): string[] {
    return ['NL', 'FR'];
  }

  getCurrentLocale() {
    if(this.currentLocale) {
      return this.currentLocale;
    } else {
      return this.defaultLocale;
    }
  }

  setDefault() {
    this.translateService.setDefaultLang(this.defaultLocale.toLowerCase());
  }

  restoreSaved(): Observable<any> {
    return Observable.create(observer => {
      let stored = localStorage.getItem(LocaleService.StorageKeyLocale);
      if(stored) {
        this.currentLocale = stored;
        this.onLanguageChanged.emit(this.currentLocale);
        this.translateService.use(this.currentLocale.toLowerCase()).subscribe(() => {
          observer.next(true);
          observer.complete();
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  use(locale: string): Observable<any> {
    locale = locale.toUpperCase();

    let sl = this.getSupportedLocals();

    if(sl.includes(locale)) {
      this.currentLocale = locale;
      this.onLanguageChanged.emit(this.currentLocale);
      localStorage.setItem(LocaleService.StorageKeyLocale, this.currentLocale);
      return this.translateService.use(this.currentLocale.toLowerCase());
    } else {
      return Observable.create(observer => {
        observer.next(false);
        observer.complete();
      });
    }
  }
}
