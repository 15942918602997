import { Injectable } from '@angular/core';
import { Glove } from '../models/glove';
import { IEnCode } from '../models/ien-code';

@Injectable({
  providedIn: 'root'
})
export class EnCodesService {

  constructor() { }

  public getEn407Value(glove: Glove): string {
    let resultArray = [];
    glove.en_codes.forEach((enCode: IEnCode) => {
      if (enCode.code === "EN4071") {
        resultArray[0] = enCode.value;
      }

      if (enCode.code === "EN4072") {
        resultArray[1] = enCode.value;
      }

      if (enCode.code === "EN4073") {
        resultArray[2] = enCode.value;
      }

      if (enCode.code === "EN4074") {
        resultArray[3] = enCode.value;
      }

      if (enCode.code === "EN4075") {
        resultArray[4] = enCode.value;
      }

      if (enCode.code === "EN4076") {
        resultArray[5] = enCode.value;
      }
    });

    return resultArray.join('');
  }

  public getEn388Shieldvalue(glove: Glove): string {
    let resultArray = [];
    glove.en_codes.forEach((enCode: IEnCode) => {
      if (enCode.code === "EN3881") {
        resultArray[0] = enCode.value;
      }

      if (enCode.code === "EN3882") {
        resultArray[1] = enCode.value;
      }

      if (enCode.code === "EN3883") {
        resultArray[2] = enCode.value;
      }

      if (enCode.code === "EN3884") {
        resultArray[3] = enCode.value;
      }

      if (enCode.code === "EN388X" && enCode.value !== 'N') {
        resultArray[4] = enCode.value;
      }
    });

    return resultArray.join('');
  }

  public getEn3741Value(glove: Glove) {
    let result = '';

    glove.en_codes.forEach((enCode: IEnCode) => {
      if (enCode.code === 'EN3741A') {
        result = enCode.value;
      }

      if (enCode.code === 'EN3741B') {
        result += '<br>' + enCode.value;
      }
    });

    return result;
  }

  public getEn511Value(glove: Glove) {
    let result = '';

    glove.en_codes.forEach((enCode: IEnCode) => {
      if (['EN5111', 'EN5112', 'EN511X'].includes(enCode.code)) {
        result += enCode.value + ' ';
      }
    });

    return result;
  }
}
