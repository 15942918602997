import { Component, OnInit, Input } from '@angular/core';
import { IEnCode } from 'src/app/models/ien-code';

@Component({
  selector: 'app-en388x-iso-cut-resistance',
  templateUrl: './en388x-iso-cut-resistance.component.html',
  styleUrls: ['./en388x-iso-cut-resistance.component.scss']
})
export class En388xIsoCutResistanceComponent implements OnInit {

  public options: string[] = ['A', 'B', 'C', 'D', 'F'];

  @Input() public enCode: IEnCode;

  constructor() { }

  ngOnInit() {
  }

}
