import { Component, OnInit, Input } from '@angular/core';
import { IEnCode } from 'src/app/models/ien-code';

@Component({
  selector: 'app-en407',
  templateUrl: './en407.component.html',
  styleUrls: ['./en407.component.scss']
})
export class En407Component implements OnInit {

  @Input() public enCode: IEnCode;

  constructor() { }

  ngOnInit() {
  }
}
