import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './pages/home/home.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { StepTypesComponent } from './pages/step-types/step-types.component';
import { StepCategoriesComponent } from './pages/step-categories/step-categories.component';
import { StepOverviewComponent } from './pages/step-overview/step-overview.component';
import { StepGloveDetailComponent } from './pages/step-glove-detail/step-glove-detail.component';

import { LocaleService } from './services/locale.service';
import { SizeModalComponent } from './modals/size-modal/size-modal.component';
import { StepChoiceComponent } from './pages/step-choice/step-choice.component';
import { ProductGroupLabelComponent } from './components/product-group-label/product-group-label.component';
import { En3881AbrasionResistanceComponent } from './components/en_codes/en3881-abrasion-resistance/en3881-abrasion-resistance.component';
import { En3882CutResistanceComponent } from './components/en_codes/en3882-cut-resistance/en3882-cut-resistance.component';
import { En3883TearResistanceComponent } from './components/en_codes/en3883-tear-resistance/en3883-tear-resistance.component';
import { En3884PunctureResistanceComponent } from './components/en_codes/en3884-puncture-resistance/en3884-puncture-resistance.component';
import { En388xIsoCutResistanceComponent } from './components/en_codes/en388x-iso-cut-resistance/en388x-iso-cut-resistance.component';
import { En407Component } from './components/en_codes/en407/en407.component';
import { En511Component } from './components/en_codes/en511/en511.component';

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopBarComponent,
    StepTypesComponent,
    StepCategoriesComponent,
    StepOverviewComponent,
    StepGloveDetailComponent,
    SizeModalComponent,
    StepChoiceComponent,
    ProductGroupLabelComponent,
    En3881AbrasionResistanceComponent,
    En3882CutResistanceComponent,
    En3883TearResistanceComponent,
    En3884PunctureResistanceComponent,
    En388xIsoCutResistanceComponent,
    En407Component,
    En511Component
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    SlickCarouselModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (LanguageLoader),
        deps: [HttpClient]
      }
    })
  ],
  entryComponents: [
    SizeModalComponent
  ],
  providers: [
    LocaleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
