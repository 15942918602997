import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductGroupService {

  constructor() { }

  public getBackgroundColor(productGroup: string, parentCategoryKey: string) {
    if (productGroup === 'Specials') {
      return '#91005A';
    }

    if (parentCategoryKey.includes('K')) {
      return '#0e7db8';
    }

    if (parentCategoryKey.includes('H')) {
      return '#e8822c';
    }

    if (parentCategoryKey.includes('T')) {
      return '#4dae46';
    }

    console.error('Unkwown color: ' + productGroup + ' ' + parentCategoryKey);
  }

  public imageAvailable(productGroup: string) {
    return ['Specials','Standard', 'Basic', 'Classic'].includes(productGroup);
  }
}
