import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { StepTypesComponent } from './pages/step-types/step-types.component';
import { StepCategoriesComponent } from './pages/step-categories/step-categories.component';
import { StepOverviewComponent } from './pages/step-overview/step-overview.component';
import { StepGloveDetailComponent } from './pages/step-glove-detail/step-glove-detail.component';
import { StepChoiceComponent } from './pages/step-choice/step-choice.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'types', component: StepTypesComponent },
  { path: 'categories/:id', component: StepCategoriesComponent, pathMatch: 'full' },
  { path: 'overview/:id', component: StepOverviewComponent },
  { path: 'product/:id/:categoryId', component: StepGloveDetailComponent },
  { path: 'good-choice/:id', component: StepChoiceComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
