import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    window.localStorage.removeItem('busters-overview-filters');
  }

  btnGoTypes(event?) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.router.navigateByUrl('types');
  }
}
