import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ICategory } from 'src/app/models/icategory';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends ApiService {
  public getParentCategories(): Observable<ICategory[]> {
    return new Observable((observer) => {
      this.http.get(this.basePath + 'categories', this.getOptions()).subscribe((response: any) => {
        observer.next(response.data);
      });
    });
  }

  public getChildCategories(categoryId: number): Observable<ICategory[]> {
    return new Observable((observer) => {
      this.http.get(this.basePath + 'categories/' + categoryId, this.getOptions()).subscribe((response: any) => {
        observer.next(response.data);
      });
    });
  }

  public getCategory(categoryId: number): Observable<ICategory> {
    return this.http.get(this.basePath + 'categories/' + categoryId + '/details', this.getOptions()).pipe(map((res: any) => res.data));
  }
}
