import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/services/api/category.service';
import { ICategory } from 'src/app/models/icategory';

@Component({
  selector: 'app-step-types',
  templateUrl: './step-types.component.html',
  styleUrls: ['./step-types.component.scss']
})
export class StepTypesComponent implements OnInit {

  public categories: ICategory[] = [];

  constructor(
    private router: Router,
    private categoryService: CategoryService
  ) {}

  ngOnInit() {
    this.categoryService.getParentCategories().subscribe((categories: ICategory[]) => this.categories = categories);
  }

  btnSelectType(category: ICategory, event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.router.navigate(['categories', category.id]);
  }

  btnGoBack(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.router.navigateByUrl('/');
  }
}
