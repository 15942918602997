import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class GloveService extends ApiService {
  public getGloves(params: any = {}) {
    let query = '';

    if (params.categoryId) {
      query = '?category=' + params.categoryId;
    }

    return this.http.get(this.basePath + 'gloves' + query, this.getOptions());
  }

  public getGlove(id: number) {
    return this.http.get(this.basePath + 'gloves/' + id, this.getOptions()).pipe(map((res: any) => res.data));
  }
}
