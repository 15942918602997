import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SizeModalComponent } from 'src/app/modals/size-modal/size-modal.component';
import { GloveService } from 'src/app/services/api/glove.service';
import { Glove } from 'src/app/models/glove';
import { ProductGroupService } from 'src/app/services/product-group.service';
import { CategoryService } from 'src/app/services/api/category.service';
import { ICategory } from 'src/app/models/icategory';
import { DomSanitizer } from '@angular/platform-browser';
import { IEnCode } from 'src/app/models/ien-code';
import { EnCodesService } from 'src/app/services/en-codes.service';

@Component({
  selector: 'app-step-glove-detail',
  templateUrl: './step-glove-detail.component.html',
  styleUrls: ['./step-glove-detail.component.scss']
})
export class StepGloveDetailComponent implements OnInit {
  public doPreviewTransition: boolean;
  public slideConfig: any;
  public slideIndex: number;
  public allSpecsShown: boolean;
  public glove: Glove;
  public category: ICategory;
  public enCodesForRanges: Array<IEnCode> = [];
  public enCodesForShields: Array<IEnCode> = [];

  @ViewChild('thumbsContainer',  null) thumbsContainer: ElementRef;

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private gloveService: GloveService,
    private categoryService: CategoryService,
    private productGroupService: ProductGroupService,
    private sanatizer: DomSanitizer,
    private enCodeService: EnCodesService
  ) {
    this.slideIndex = 0;
    this.slideConfig = {
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 300,
      draggable: true,
      infinite: true,
      swipe: true,
      initialSlide: this.slideIndex
    };
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = +params.id;
      const categoryId = +params.categoryId;

      this.gloveService.getGlove(id).subscribe((glove: Glove) => {
        this.glove = glove;

        if (this.glove.usps.length <= 2) {
          this.allSpecsShown = true;
        }

        this.updateEnCodes();
      });

      this.categoryService.getCategory(categoryId).subscribe((category: ICategory) => {
        this.category = category;
      });
    });
  }

  nextImage(carousel, event?) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    carousel.slickNext();
  }

  previousImage(carousel, event?) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    carousel.slickPrev();
  }

  beforeImageChange(event) {
    this.slideIndex = event.nextSlide;

    let nativeElement = this.thumbsContainer.nativeElement;
    let slideElement = nativeElement.children[this.slideIndex];

    let value = (slideElement.offsetLeft) - (nativeElement.offsetWidth/2) + (slideElement.offsetWidth/2);
    nativeElement.scrollTo(value, 0);
  }

  showAllSpecs(event?)  {
    if(event) {
      event.preventDefault();
    }

    this.allSpecsShown = !this.allSpecsShown;
  }

  showSizes(event?) {
    if(event) {
      event.preventDefault();
    }

    this.modalService.show(SizeModalComponent, {

    });
  }

  btnMakeChoice(event?) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.router.navigate(['good-choice', this.glove.id]);
  }

  btnClose(event?) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!this.category) {
      return;
    }

    this.router.navigate(['overview', this.category.id]);
  }

  public imageAvailable() {
    if (!this.glove) {
      return;
    }

    return this.productGroupService.imageAvailable(this.glove.product_range);
  }

  public getBackgroundColor() {
    if (!this.glove || !this.category) {
      return;
    }

    return this.productGroupService.getBackgroundColor(this.glove.product_range, this.category.key);
  }

  public sanitizeImageUrl(url: string) {
    let encodedUrl = encodeURI(url);
    return this.sanatizer.bypassSecurityTrustStyle("url("+encodedUrl+")");
  }

  public onChangeColor(value: number) {
    if (!this.category) {
      return;
    }

    this.router.navigate(['product', value, this.category.id]);
  }

  private updateEnCodes() {
    if (!this.glove) {
      return;
    }

    this.enCodesForRanges = [];
    this.enCodesForShields = [];

    this.glove.en_codes.forEach((enCode: IEnCode) => {
      if (['EN3881', 'EN3882', 'EN3883', 'EN3884', 'EN388X', 'EN5111', 'EN5112', 'EN511X'].includes(enCode.code)) {
        this.enCodesForRanges.push(enCode);
      }

      if (enCode.code.includes('EN407')) {
        this.enCodesForRanges.push(enCode);
      }

      if (enCode.code === "EN3881") {
        this.enCodesForShields.push({
          code: 'EN 388:2016',
          value: this.enCodeService.getEn388Shieldvalue(this.glove),
          icon: 'assets/images/shield-1@2x.png'
        });
      }

      if (enCode.code === "EN12477") {
        enCode.code += ':2002';
        this.enCodesForShields.push(enCode);
      }

      if (enCode.code === "EN4071") {
        this.enCodesForShields.push({
          code: 'EN 407:2004',
          value: this.enCodeService.getEn407Value(this.glove),
          icon: 'assets/images/shield-7@2x.png'
        });
      }

      if (enCode.code === "EN374" && enCode.value === "Y") {
        this.enCodesForShields.push({
          code: 'EN 374:2016',
          value: this.enCodeService.getEn3741Value(this.glove),
          icon: 'assets/images/shield-6@2x.png'
        });
      }

      if (enCode.code === "EN3745") {
        this.enCodesForShields.push({
          code: 'EN 374-5:2016',
          value: '',
          icon: 'assets/images/shield-5@2x.png'
        });
      }

      if (enCode.code === "EN511" && enCode.value === "Y") {
        this.enCodesForShields.push({
          code: 'EN511:2006',
          value: this.enCodeService.getEn511Value(this.glove),
          icon: 'assets/images/shield-2@2x.png'
        });
      }
    });

  }
}
