import { Component, OnInit, Input } from '@angular/core';
import { IEnCode } from 'src/app/models/ien-code';

@Component({
  selector: 'app-en511',
  templateUrl: './en511.component.html',
  styleUrls: ['./en511.component.scss']
})
export class En511Component implements OnInit {

  @Input() public enCode: IEnCode;

  constructor() { }

  ngOnInit() {
  }

}
