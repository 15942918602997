import { Component, OnInit, Input } from '@angular/core';
import { IEnCode } from 'src/app/models/ien-code';

@Component({
  selector: 'app-en3881-abrasion-resistance',
  templateUrl: './en3881-abrasion-resistance.component.html',
  styleUrls: ['./en3881-abrasion-resistance.component.scss']
})
export class En3881AbrasionResistanceComponent implements OnInit {

  public options: number[] = [0, 1, 2, 3, 4];

  @Input() public enCode: IEnCode;

  constructor() { }

  ngOnInit() {
  }

}
