import { Component, OnInit, Input } from '@angular/core';
import { ProductGroupService } from 'src/app/services/product-group.service';

@Component({
  selector: 'app-product-group-label',
  templateUrl: './product-group-label.component.html',
  styleUrls: ['./product-group-label.component.scss']
})
export class ProductGroupLabelComponent implements OnInit {

  @Input()
  public parentCategoryKey: string;

  @Input()
  public productGroup: string;

  constructor(private productGroupService: ProductGroupService) { }

  ngOnInit() {
  }

  public imageAvailable() {
   return this.productGroupService.imageAvailable(this.productGroup);
  }

  public getBackgroundColor() {
    return this.productGroupService.getBackgroundColor(this.productGroup, this.parentCategoryKey);
  }

}
