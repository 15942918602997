import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocaleService } from 'src/app/services/locale.service';
import { Glove } from 'src/app/models/glove';
import { GloveService } from 'src/app/services/api/glove.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-step-choice',
  templateUrl: './step-choice.component.html',
  styleUrls: ['./step-choice.component.scss']
})
export class StepChoiceComponent implements OnInit {

  public glove: Glove;

  constructor(
    private router: Router,
    private localeService: LocaleService,
    private route: ActivatedRoute,
    private gloveService: GloveService,
    private sanatizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = +params.id;
      this.gloveService.getGlove(id).subscribe((glove: Glove) => this.glove = glove);
    });
  }

  btnRestart(event?) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.router.navigateByUrl('types');
  }

  public sanitizeImageUrl(url: string) {
    let encodedUrl = encodeURI(url);
    return this.sanatizer.bypassSecurityTrustStyle("url("+encodedUrl+")");
  }
}
