import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocaleService } from '../locale.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  protected basePath = 'https://busters.digiti.be/api/';

  constructor(protected http: HttpClient, protected localeService: LocaleService) { }

  protected getOptions() {
    return {
      headers: {
        'X-localization': this.localeService.getCurrentLocale()
      }
    }
  }
}
