import { Component, OnInit } from '@angular/core';
import { LocaleService } from 'src/app/services/locale.service';
import { browser } from 'protractor';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  public selectedLocale: string;
  public supportedLocals: string[];

  constructor(
    private localService: LocaleService
  ) {}

  ngOnInit() {
    this.supportedLocals = this.localService.getSupportedLocals();

    this.selectedLocale = this.localService.getCurrentLocale();
    this.localService.onLanguageChanged.subscribe(locale => {
      this.selectedLocale = locale;
    });
  }

  onLanguageSelect(event) {
    this.localService.use(this.selectedLocale);
    location.reload();
  }

}
