import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GloveService } from 'src/app/services/api/glove.service';
import { group } from '@angular/animations';
import { ICategory } from 'src/app/models/icategory';
import { Glove } from 'src/app/models/glove';
import { DomSanitizer } from '@angular/platform-browser';
import { IFilterOption } from 'src/app/models/ifilter-option';

@Component({
  selector: 'app-step-overview',
  templateUrl: './step-overview.component.html',
  styleUrls: ['./step-overview.component.scss']
})
export class StepOverviewComponent implements OnInit {
  static StorageKeyOverviewScroll = 'busters-overview-scroll';
  static StorageKeyFilters = 'busters-overview-filters';

  public glovesByGroup: any;
  public filteredGloves: any;
  public category: ICategory;
  public filterOptions: Array<IFilterOption> = [];
  public productRanges: Array<any> = [];
  public productRangeOrder: Array<string> = ['Standard', 'Eco', 'Specials', 'Classic', 'Basic'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private gloveService: GloveService,
    private sanatizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = +params.id;

      this.gloveService.getGloves({categoryId: id}).subscribe((data: any) => {
        this.glovesByGroup = data.gloves;
        this.filteredGloves = [];

        this.category = data.category;

        this.productRanges = data.ranges;

        this.updateFilterOptions();
        this.updateFilterOption();

        this.restoreScrollPositionIfPresent();
      });
    });

  }

  openGloveDetails(glove: Glove, event?) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.saveScrollPosition();
    this.saveFilters();
    this.router.navigate(['product', glove.id, this.category.id]);
  }

  btnGoBack(event?) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.router.navigate(['categories', this.category.parent_id]);
  }

  protected saveScrollPosition() {
    if(window && window.localStorage) {
      let scroll = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
      window.localStorage.setItem(StepOverviewComponent.StorageKeyOverviewScroll, String(scroll));
    }
  }

  restoreScrollPositionIfPresent() {
    if(window && window.localStorage) {
      let storedValue = window.localStorage.getItem(StepOverviewComponent.StorageKeyOverviewScroll);

      if(storedValue) {
        let scroll = Number(storedValue);
        setTimeout(() => {
          window.scrollTo(0, scroll);
        });
        window.localStorage.removeItem(StepOverviewComponent.StorageKeyOverviewScroll);
      }
    }
  }

  static resetScrollPosition() {
    if(window && window.localStorage) {
      window.localStorage.removeItem(StepOverviewComponent.StorageKeyOverviewScroll);
    }
  }

  public getBackgroundImage(glove: Glove) {
    if (glove.images.length == 0) {
      return '';
    }

    let encodedUrl = encodeURI(glove.images[0].thumb);
    return this.sanatizer.bypassSecurityTrustStyle("url("+encodedUrl+")");
  }

  protected updateFilterOptions() {
    this.filterOptions = [];

    let values = [];

    for (let key in this.glovesByGroup) {
      let gloves = this.glovesByGroup[key];
      gloves.forEach((glove: Glove) => {
        glove.properties.forEach(prop => {
          if (!values.includes(prop)) {
            values.push(prop);
          }
        });
      });
    }

    values.forEach((value) => {
      this.filterOptions.push({option: value, checked: false});
    });

    this.restoreFilters();
  }

  public updateFilterOption(option: IFilterOption = null) {
    if (option) {
      option.checked = !option.checked;
    }

    let checkedOptions = this.getCheckedFilterValues();

    this.filteredGloves = {};

    for (let key in this.glovesByGroup) {
      let gloves = this.glovesByGroup[key];
      gloves.forEach((glove: Glove) => {
        let show = checkedOptions.every(v => glove.properties.includes(v));

        if (!this.filteredGloves[key]) this.filteredGloves[key] = [];
        if (show || checkedOptions.length == 0) this.filteredGloves[key].push(glove);
      });
    }
  }

  protected getCheckedFilterValues(): Array<string> {
    let results = [];

    this.filterOptions.forEach((option: IFilterOption) => {
      if (option.checked) results.push(option.option);
    });

    return results;
  }

  public countResults(): number {
    let total = 0;

    if (!this.filteredGloves) {
      return -1;
    }

    for (let key in this.filteredGloves) {
      let gloves = this.filteredGloves[key];
      if (gloves) {
        total += gloves.length;
      }
    }

    return total;
  }

  protected saveFilters() {
    if(window && window.localStorage) {
      let filters = this.getCheckedFilterValues().join(',');
      window.localStorage.setItem(StepOverviewComponent.StorageKeyFilters, filters);
    }
  }

  protected restoreFilters() {
    if(window && window.localStorage) {
      const storedValue = window.localStorage.getItem(StepOverviewComponent.StorageKeyFilters);

      if (!storedValue) return;

      const filterOptions = storedValue.split(',');

      this.filterOptions.forEach((option: IFilterOption) => {
        if (filterOptions.includes(option.option)) {
          option.checked = true;
        }
      });

      this.filterOptions.sort((a, b) => (a.checked < b.checked) ? 1 : -1)
    }
  }

  public getRangeDescription(title: string) {
    let result = '';
    this.productRanges.forEach((element) => {
      if (element.title === title) {
        result = element.description;
      }
    });

    return result;
  }
}
